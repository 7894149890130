// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-cat-template-js": () => import("./../src/templates/cat-template.js" /* webpackChunkName: "component---src-templates-cat-template-js" */),
  "component---src-templates-works-template-js": () => import("./../src/templates/works-template.js" /* webpackChunkName: "component---src-templates-works-template-js" */),
  "component---src-templates-workspost-template-js": () => import("./../src/templates/workspost-template.js" /* webpackChunkName: "component---src-templates-workspost-template-js" */)
}

